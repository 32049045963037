import { removeAllFromClass } from '../helpers/dom-helpers';

// DROPDOWNS
const dropdownClass = 'js-dropdown';
const dropdownTriggerClass = 'js-dropdown-trigger';
const activeClass = 'is-active';

const jsDropdowns = document.querySelectorAll(`.${dropdownTriggerClass}`);
jsDropdowns.forEach(item => {
    item.addEventListener('click', e => {
        const { currentTarget } = e;

        if (currentTarget.classList.contains(activeClass)) {
            removeAllFromClass(
                dropdownTriggerClass,
                activeClass,
                currentTarget
            );
        } else {
            jsDropdowns.forEach(item => {
                removeAllFromClass(dropdownTriggerClass, activeClass, item);
            });

            currentTarget.classList.add(activeClass);
        }
    });

    const anchor = item.querySelectorAll('a');
    if (anchor) {
        anchor.forEach(link => {
            link.addEventListener('click', e => {
                e.stopPropagation();
            });
        });
    }
});

document.addEventListener('scroll', function (e) {
    removeAllFromClass(dropdownTriggerClass, activeClass);
});

document.addEventListener('click', e => {
    const isDropdownClick = Array.from(jsDropdowns).some(item =>
        item.contains(e.target)
    );

    if (!isDropdownClick) {
        removeAllFromClass(dropdownTriggerClass, activeClass);
    }
});

// RIGHT BORDER OVERFLOW FALLBACK
const dropdowns = document.getElementsByClassName(dropdownClass);
if (dropdowns.length > 0) {
    [].forEach.call(dropdowns, el => {
        var rect = el.getBoundingClientRect();
        if (
            rect.right >=
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
            el.style.transform =
                'translate( ' + (window.innerWidth - rect.right) + 'px, 0)';
        }
    });
}

//MOBILE NAV
const mobileTriggerId = 'js-dropdown-main-trigger';
const mobileNavId = 'js-dropdown-main-nav';
const mobileTrigger = document.getElementById(mobileTriggerId);
const mobileNav = document.getElementById(mobileNavId);

mobileTrigger.addEventListener('click', event => {
    if (mobileTrigger.classList.contains(activeClass)) {
        mobileTrigger.classList.remove(activeClass);
        mobileNav.classList.remove(activeClass);
        document.documentElement.style.overflowY = 'auto';
    } else {
        mobileTrigger.classList.add(activeClass);
        mobileNav.classList.add(activeClass);
        document.documentElement.style.overflowY = 'hidden';
    }
});
